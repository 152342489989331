import type { ICountry } from './Country';
import Country from './Country';
import type { IProduct } from './Product';
import Product from './Product';

export interface IMarketplace {
    id?: number;
    name?: string;
    created_at: Date;
    updated_at: Date;
    mws_id: string;
    asin: string;
    product_id: number;
    product?: IProduct;
    countries: Array<ICountry>;
    pivot: {
        country_id: number;
    };
    country_code?: string;
}

export default class Marketplace {
    id?: number;
    created_at: Date = new Date();
    updated_at: Date = new Date();
    mws_id = '';

    country_code = '';
    default_currency_code = '';
    default_language_code = '';
    domain_name = '';
    endpoint = '';
    name = '';
    region_code = '';
    pivot: {
        country_id: number;
    } = { country_id: 0 };

    toJSON(): IMarketplace {
        //console.debug("Marketplace.toJSON()", this);
        return Object.assign({} as IMarketplace, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: IMarketplace): Marketplace {
        //console.debug("Marketplace.fromJSON()", json);
        const user = Object.create(Marketplace.prototype);
        return Object.assign(user, json, {
            created_at: new Date(json.created_at),
            updated_at: new Date(json.updated_at),
            countries:
                json.countries?.map((country) => Country.fromJSON(country)) ??
                [],
            product: json.product ? Product.fromJSON(json.product) : undefined,
        });
    }
}
