import { useToast } from 'bootstrap-vue-next';
import LaravelError from '@/api/model/LaravelError';
import { ref } from 'vue';

export function useErrors() {
    const { show } = useToast();

    const validationErrors = ref<Record<string, string | string[]>>({});

    function showErrors(
        error: unknown,
        message: string,
    ): Record<string, string | string[]> {
        if (error instanceof Error) {
            console.error(message, error.message, error);
        } else {
            console.error(message, error);
        }
        if (show) {
            show({
                props: {
                    title: 'Error',
                    body: message,
                    variant: 'danger',
                },
            });
        }

        if (error instanceof LaravelError) {
            validationErrors.value = error.errors;
        }

        return error instanceof LaravelError ? error.errors : {};
    }

    return { showErrors, validationErrors };
}
