import axios from 'axios';
import type { SuccessResponse } from '@/api/types/Auth';
import type { Resource } from '@/api/model/Resource';
import type { UserJson } from '@/api/model/User';
import { User } from '@/api/model/User';

export default function useUserService() {
    return {
        async updateProfile(data: FormData): Promise<User> {
            const response = await axios.post<Resource<UserJson>>(
                `/api/profile`,
                data,
            );
            return User.fromJSON(response.data.data);
        },

        async updatePassword(
            current_password: string,
            password: string,
            password_confirmation: string,
        ): Promise<SuccessResponse> {
            return await axios
                .put<SuccessResponse>('/api/profile/update-password', {
                    current_password: current_password,
                    password: password,
                    password_confirmation: password_confirmation,
                })
                .then((response) => response.data);
        },
    };
}
