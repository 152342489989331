<script setup>
import { onMounted } from 'vue';
import { useMenuStore } from '@/stores/menuStore';
import { useTemplateStore } from '@/stores/template';
import { useOrganisationStore } from '@/stores/organisationStore';

import BaseNavigation from '@/components/BaseNavigation.vue';

// SimpleBar, for more info and examples you can check out https://github.com/Grsmto/simplebar/tree/master/packages/simplebar-vue
import SimpleBar from 'simplebar';

// Component properties
defineProps({
    withMiniNav: {
        type: Boolean,
        default: false,
        description: 'If the sidebar is in Mini Nav Mode',
    },
});

// Main store
const store = useTemplateStore();
const orgStore = useOrganisationStore();
const menuStore = useMenuStore();

// Init SimpleBar (custom scrolling)
onMounted(() => {
    new SimpleBar(document.getElementById('simplebar-sidebar'));
});
</script>

<template>
    <!-- Sidebar -->
    <!--
    Sidebar Mini Mode - Display Helper classes

    Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
    Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
    If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

    Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
    Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
    Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
  -->
    <nav
        id="sidebar"
        :class="{ 'with-mini-nav': withMiniNav }"
        aria-label="Main Navigation"
    >
        <slot>
            <!-- Side Header -->
            <div class="content-header">
                <slot name="header">
                    <!-- Logo -->
                    <RouterLink
                        :to="{
                            name: 'dashboard',
                            params: {
                                organisation:
                                    orgStore.current_organisation.ulid,
                            },
                        }"
                        class="fw-semibold text-dual"
                    >
                        <span class="smini-visible">
                            <i class="fa fa-circle-notch text-primary"></i>
                        </span>
                        <span class="smini-hide fs-5 tracking-wider">
                            Sellopod
                            <span class="fw-normal">Vue</span>
                        </span>
                    </RouterLink>
                    <!-- END Logo -->
                </slot>

                <!-- Extra -->
                <div>
                    <!-- Close Sidebar, Visible only on mobile screens -->
                    <button
                        type="button"
                        class="d-lg-none btn btn-sm btn-alt-secondary ms-1"
                        @click="store.sidebar({ mode: 'close' })"
                    >
                        <i class="fa fa-fw fa-times"></i>
                    </button>
                    <!-- END Close Sidebar -->
                </div>
                <!-- END Extra -->
            </div>
            <!-- END Side Header -->

            <!-- Sidebar Scrolling -->
            <div id="simplebar-sidebar" class="js-sidebar-scroll">
                <slot name="content">
                    <!-- Side Navigation -->
                    <div class="content-side">
                        <BaseNavigation :nodes="menuStore.menu" />
                    </div>
                    <!-- END Side Navigation -->
                </slot>
            </div>
            <!-- END Sidebar Scrolling -->
        </slot>
    </nav>
    <!-- END Sidebar -->
</template>
