import { DateTime } from 'luxon';

export interface ISalesChannel {
    id?: number;
    ulid: string;
    name?: string;
    type?: string;
    created_at: string;
    updated_at: string;
    orders_exists?: boolean;
    sales_channel_pivot: {
        report_source_id: number;
        sales_channel_id: number;
        created_at: string;
        updated_at: string;
        name: string;
        type: string;
    };
}

export default class SalesChannel {
    id = 0;
    name = '';
    type = '';
    ulid = '';
    created_at: DateTime = DateTime.local();
    updated_at: DateTime = DateTime.local();
    orders_exists = true;
    sales_channel_pivot = {
        report_source_id: 0,
        sales_channel_id: 0,
        created_at: '',
        updated_at: '',
        name: '',
        type: '',
    };

    toJSON(): ISalesChannel {
        //console.debug("SalesChannel.toJSON()", this);
        return Object.assign({} as ISalesChannel, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: ISalesChannel): SalesChannel {
        //console.debug("SalesChannel.fromJSON()", json);
        const user = Object.create(SalesChannel.prototype);
        return Object.assign(user, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
        });
    }
}
