import axios from 'axios';
import type { ResourceCollection } from '@/api/model/ResourceCollection';
import type { IProduct } from '@/api/model/Product';
import Product from '@/api/model/Product';
import type { Resource } from './model/Resource';
import type { WithPurchaseOrder } from '@/api/model/PurchaseOrderItem';
import type PurchaseOrderItem from '@/api/model/PurchaseOrderItem';
import type { Organisation } from '@/api/model/Organisation';
import type { SuccessResponse } from './types/Shared';

export type MinStockRequest = {
    [key: number]: {
        [key: number]: number | null | undefined;
    };
};

type StockRemaining = {
    remaining_days: number;
    remaining_months: number;
    oos: string;
};

export type InventoryData = {
    [key: string]: {
        [key: string]: {
            sku_id: number;
            country_code: string; // yes
            country_name: string; //yes
            poi: PurchaseOrderItem & WithPurchaseOrder;
            stockRowPoiData: {
                quantity_balance: number; // yes
                eta: string; // yes
            };
            minStock: number | null; // yes
            stock: number; // yes
            stock_w_inbound: number; // yes
            stock_w_inbound_w_current_po: number; // yes
            forecast: number; // yes
            soh_f: StockRemaining; // yes
            soh_s: StockRemaining; // yes
            sohwi_f: StockRemaining; // yes
            sohwi_s: StockRemaining; // yes
            sohwiwpo_f: StockRemaining; // yes
            sohwiwpo_s: StockRemaining; // yes
        };
    };
};

export type MinStockData = {
    [key: string]: {
        sku_id: number;
        country_code: string;
        country_name: string;
        minStock?: number;
        forecast_d: number;
        avg_sold_d_3m: number;
    }[];
};

export type StockPerWarehouse = {
    [key: string]: {
        sku_id: string;
        name: string;
        quantity: number;
    }[];
};

export default function useProductService() {
    return {
        async getAll(org: Organisation): Promise<Product[]> {
            const response = await axios.get<ResourceCollection<IProduct>>(
                `/api/organisations/${org.ulid}/products`,
            );
            return response.data.data.map((product) =>
                Product.fromJSON(product),
            );
        },

        async get(org: Organisation, id: number): Promise<Product> {
            const response = await axios.get<Resource<IProduct>>(
                `/api/organisations/${org.ulid}/products/${id}`,
            );
            return Product.fromJSON(response.data.data);
        },

        async updateProduct(
            org: Organisation,
            id: number,
            data: Product,
        ): Promise<Product> {
            const response = await axios.put<Resource<IProduct>>(
                `/api/organisations/${org.ulid}/products/${id}`,
                data,
            );
            return Product.fromJSON(response.data.data);
        },

        async storeProduct(org: Organisation, name: string): Promise<Product> {
            const response = await axios.post<Resource<IProduct>>(
                `/api/organisations/${org.ulid}/products`,
                { name },
            );
            return Product.fromJSON(response.data.data);
        },

        async deleteProduct(
            org: Organisation,
            id: number,
        ): Promise<SuccessResponse> {
            return await axios
                .delete<SuccessResponse>(
                    `/api/organisations/${org.ulid}/products/${id}`,
                )
                .then((response) => response.data);
        },

        async getMinStock(
            org: Organisation,
            product: Product,
        ): Promise<MinStockData> {
            return axios
                .get<{
                    data: MinStockData;
                }>(
                    `/api/organisations/${org.ulid}/products/${product.id}/min-stock`,
                )
                .then((response) => response.data.data);
        },

        async updateMinStock(
            org: Organisation,
            product: Product,
            data: MinStockRequest,
        ): Promise<void> {
            await axios.put<Resource<IProduct>>(
                `/api/organisations/${org.ulid}/products/${product.id}/min-stock`,
                { min_stock: data },
            );
        },

        async getStockData(
            org: Organisation,
            product: Product,
        ): Promise<InventoryData> {
            const response = await axios.get<{ data: InventoryData }>(
                `/api/organisations/${org.ulid}/products/${product.id}/stock-data`,
            );
            return response.data.data;
        },

        async getStockPerWarehouse(
            org: Organisation,
            product: Product,
        ): Promise<StockPerWarehouse> {
            const response = await axios.get<{
                data: StockPerWarehouse;
            }>(
                `/api/organisations/${org.ulid}/products/${product.id}/stock-per-warehouse`,
            );
            return response.data.data;
        },
    };
}
