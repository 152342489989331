import { defineStore } from 'pinia';
import type { Organisation } from '@/api/model/Organisation';
import { DateTime } from 'luxon';
import { useReportSourceStore } from '@/stores/reportSourceStore';
import { useProductStore } from '@/stores/productStore';
import useOrganisationService from '@/api/OrganisationService';
import type {
    UpdateOrganisationConfig,
    SuccessResponse,
    OrganisationInviteTeamMemberRequest,
} from '@/api/types/Organisation';
import type { User } from '@/api/model/User';

interface State {
    isFetchingOrganisations: boolean;
    organisations: Organisation[];
    current_organisation: Organisation;
    loading: boolean;
}

const organisationService = useOrganisationService();

export const useOrganisationStore = defineStore('organisation', {
    state: (): State => ({
        isFetchingOrganisations: false,
        organisations: [],
        current_organisation: {
            id: 0,
            name: '',
            logo: '',
            ulid: localStorage.getItem('organisation') || 'unknown',
            created_at: DateTime.local(),
            updated_at: DateTime.local(),
        },
        loading: false,
    }),
    getters: {
        currentOrganisationId(): number {
            return this.current_organisation.id;
        },
    },
    actions: {
        setOrganisations(organisations: Organisation[]): Organisation[] {
            this.organisations = organisations;

            const organisation =
                this.findOrganisationByULID(
                    localStorage.getItem('organisation') ?? 'unknown',
                ) ?? organisations[0];
            this.setCurrentOrganisation(organisation);
            return organisations;
        },

        setCurrentOrganisationULID(uuid: string): void {
            localStorage.setItem('organisation', uuid);
            const organisation = this.findOrganisationByULID(uuid);
            if (organisation) {
                this.setCurrentOrganisation(organisation);
            }
        },
        setCurrentOrganisation(org: Organisation | undefined): void {
            this.current_organisation = org ?? {
                id: 0,
                name: 'Unknown',
                ulid: 'unknown',
                logo: 'Unknown',
                created_at: DateTime.local(),
                updated_at: DateTime.local(),
            };
            const reportSourceStore = useReportSourceStore();
            const productStore = useProductStore();
            reportSourceStore.refresh(this.current_organisation);
            productStore.refresh();
            localStorage.setItem(
                'organisation',
                this.current_organisation.ulid,
            );

            const route = this.router.currentRoute.value;
            if (
                route.params.organisation &&
                route.params.organisation !== this.current_organisation.ulid
            ) {
                this.router.push({
                    name: route.name,
                    params: {
                        ...route.params,
                        organisation: this.current_organisation.ulid,
                    },
                    query: route.query,
                });
            }
        },

        findOrganisationByULID(ulid: string): Organisation | undefined {
            return this.organisations.find((org: Organisation) => {
                return org.ulid === ulid;
            });
        },

        updateOrganisation(
            organisation: Organisation,
            data: FormData,
            config: UpdateOrganisationConfig,
        ): Promise<Organisation> {
            this.loading = true;
            return organisationService
                .updateOrganisation(organisation, data, config)
                .then((response) => {
                    const updatedOrganisation = this.organisations.find(
                        (org) => org.id === response.id,
                    );
                    if (updatedOrganisation) {
                        updatedOrganisation.name = response.name;
                        updatedOrganisation.logo = response.logo;
                    }
                    if (response.id === this.current_organisation.id) {
                        this.current_organisation.name = response.name;
                        this.current_organisation.logo = response.logo;
                    }
                    return response;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getOrganisationMembers(org: Organisation) {
            this.loading = true;
            return organisationService
                .getOrganisationMembers(org)
                .then((response) => {
                    return response;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async removeTeamMember(
            org: Organisation,
            user: User,
        ): Promise<SuccessResponse> {
            this.loading = true;
            return await organisationService
                .removeTeamMember(org, user.id)
                .then((res) => {
                    return res;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async createTeamMember(
            form: OrganisationInviteTeamMemberRequest,
        ): Promise<SuccessResponse> {
            this.loading = true;
            return await organisationService
                .createTeamMember(form, this.current_organisation)
                .then((res) => {
                    return res;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
});
