import { DateTime } from 'luxon';
import type { OrganisationJson } from '@/api/model/Organisation';
import { Organisation } from '@/api/model/Organisation';
import type XeroConnection from '@/api/model/XeroConnection';

export interface UserJson {
    id: number;
    email: string;
    name: string;
    profile_picture: string;
    created_at: string;
    updated_at: string;
    email_verified_at: string;
    organisations: OrganisationJson[];
    xero_connections: XeroConnection[];
}

export class User {
    id = 0;
    email = 'Unknown';
    name = 'Unknown';
    profile_picture = 'Unknown';
    created_at: DateTime = DateTime.now();
    updated_at: DateTime = DateTime.now();
    email_verified_at: DateTime = DateTime.now();
    organisations: Organisation[] = [];
    xero_connections: XeroConnection[] = [];

    static fromJSON(json: UserJson): User {
        //console.debug("User.fromJSON()", json);
        const user = Object.create(User.prototype);
        return Object.assign(user, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            email_verified_at: json.email_verified_at
                ? DateTime.fromISO(json.email_verified_at)
                : null,
            organisations: json.organisations?.map((org: OrganisationJson) =>
                Organisation.fromJSON(org),
            ),
        });
    }
}
