import axios from 'axios';
import type {
    UserRegisterRequest,
    UserUpdatePasswordRequest,
    UserUpdatePasswordResponse,
    SuccessResponse,
    LoginResponse,
} from '@/api/types/Auth';
import type { Resource } from '@/api/model/Resource';
import type { UserJson } from '@/api/model/User';
import { User } from '@/api/model/User';

export default function useAuthService() {
    return {
        getCurrentUser(): Promise<User> {
            return axios
                .get<Resource<UserJson>>(`/api/users/me`)
                .then((response) => User.fromJSON(response.data.data));
        },

        async login(email: string, password: string): Promise<LoginResponse> {
            return axios.get<void>('/sanctum/csrf-cookie').then(() => {
                return axios
                    .post<LoginResponse>(`/api/fortify/login`, {
                        email: email,
                        password: password,
                    })
                    .then((response) => response.data);
            });
        },

        async register(credentials: UserRegisterRequest): Promise<void> {
            return axios.get<void>('/sanctum/csrf-cookie').then(() => {
                return axios
                    .post<void>(`/api/fortify/register`, credentials)
                    .then((response) => response.data);
            });
        },

        async logout(): Promise<void> {
            return axios
                .post<void>('/api/fortify/logout')
                .then((response) => response.data);
        },

        async createPassword(
            createData: UserUpdatePasswordRequest,
        ): Promise<number> {
            const { status } = await axios.post<UserUpdatePasswordResponse>(
                '/api/signup/create-password',
                createData,
            );
            return status;
        },

        async forgotPassword(email: string): Promise<SuccessResponse> {
            return await axios
                .post<SuccessResponse>('/api/fortify/forgot-password', {
                    email: email,
                })
                .then((response) => response.data);
        },

        async resetPassword(
            email: string,
            token: string,
            password: string,
            password_confirmation: string,
        ): Promise<SuccessResponse> {
            return await axios
                .post<SuccessResponse>('/api/fortify/reset-password', {
                    email: email,
                    token: token,
                    password: password,
                    password_confirmation: password_confirmation,
                })
                .then((response) => response.data);
        },

        async requestEmailVerificationLink(
            email: string,
        ): Promise<SuccessResponse> {
            return await axios
                .post<SuccessResponse>(
                    '/api/fortify/email/verification-notification',
                    {
                        email: email,
                    },
                )
                .then((response) => response.data);
        },
    };
}
