import { DateTime } from 'luxon';

export interface IPaymentProcessor {
    id?: number;
    ulid: string;
    external_id: string;
    created_at: string;
    updated_at: string;
    name: string;
    type: string;
    public_key?: string;
    secret_key?: string;
}

export default class PaymentProcessor {
    id = 0;
    ulid: string = '';
    external_id: string = '';
    created_at: DateTime = DateTime.local();
    updated_at: DateTime = DateTime.local();
    name: string = '';
    type: string = '';
    public_key: string = '';
    secret_key: string = '';

    static fromJSON(json: IPaymentProcessor): PaymentProcessor {
        //console.debug('PaymentProcessor.fromJSON()', json);
        const user = Object.create(PaymentProcessor.prototype);
        return Object.assign(user, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
        });
    }
}
