export interface IWarehouseSKU {
    quantity: number;
    sku_id: number;
    warehouse_id: number;
}

export default class WarehouseSKU {
    quantity = 0;
    sku_id?: number;
    warehouse_id?: number;

    toJSON(): IWarehouseSKU {
        //console.debug("WarehouseSKU.toJSON()", this);
        return Object.assign({} as IWarehouseSKU, this, {});
    }

    static fromJSON(json: IWarehouseSKU): WarehouseSKU {
        //console.debug("WarehouseSKU.fromJSON()", json);
        const warehouseSKU = Object.create(WarehouseSKU.prototype);
        return Object.assign(warehouseSKU, json, {});
    }
}
