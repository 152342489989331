import type { ICountry } from './Country';
import type Country from './Country';

export interface IFulfillmentCenter {
    id?: number;
    created_at: Date;
    updated_at: Date;
    name: string;
    marketplace_id?: number;
    country?: ICountry;
    country_id?: number;
}

export default class FulfillmentCenter {
    id?: number;
    created_at: Date = new Date();
    updated_at: Date = new Date();
    name = '';
    marketplace_id?: number;
    country_id?: number;
    country?: Country = undefined;

    toJSON(): IFulfillmentCenter {
        //console.debug("FulfillmentCenter.toJSON()", this);
        return Object.assign({} as IFulfillmentCenter, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: IFulfillmentCenter): FulfillmentCenter {
        //console.debug("FulfillmentCenter.fromJSON()", json);
        const fulfillmentCenter = Object.create(FulfillmentCenter.prototype);
        return Object.assign(fulfillmentCenter, json, {
            created_at: new Date(json.created_at),
            updated_at: new Date(json.updated_at),
        });
    }
}
