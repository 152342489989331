import type { BadgeVariant, MenuItem } from '@/data/menu';
import menu from '@/data/menu';
import { defineStore } from 'pinia';

interface MenuState {
    menu: MenuItem[];
}

function findMenu(menu: MenuItem[], badge: string): MenuItem | undefined {
    const item = menu.find((item: MenuItem) => item.name === badge);

    if (item) {
        return item;
    }

    return menu
        .flatMap((item: MenuItem) => item.sub ?? [])
        .find((item: MenuItem) => item.name === badge);
}

export const useMenuStore = defineStore('menu', {
    state: (): MenuState => {
        return { menu: menu.main ?? [] };
    },
    actions: {
        addBadge(
            badge: string,
            count: number,
            variant: BadgeVariant = 'primary',
        ) {
            const menu = findMenu(this.menu, badge);

            if (!menu) {
                return;
            }

            menu.badge = count.toString();
            menu['badge-variant'] = variant;
        },
        removeBadge(badge: string) {
            const menu = findMenu(this.menu, badge);

            if (!menu) {
                return;
            }

            delete menu.badge;
            delete menu['badge-variant'];
        },
    },
});
