import { defineStore } from 'pinia';

import useProductService from '@/api/ProductService';
import type Product from '@/api/model/Product';
import { useOrganisationStore } from '@/stores/organisationStore';

type ProductState = {
    products: Product[];
    loading: boolean;
    loaded: boolean;
};

const productService = useProductService();

export const useProductStore = defineStore('product', {
    state: () =>
        ({
            products: [],
            loading: false,
            loaded: false,
        }) as ProductState,
    getters: {},
    actions: {
        async fetch(): Promise<Product[]> {
            if (this.loading || this.products.length > 0) {
                return Promise.resolve([]);
            }
            this.loading = true;
            const organisationStore = useOrganisationStore();
            return productService
                .getAll(organisationStore.current_organisation)
                .then((products: Product[]) => {
                    this.products = products;
                    this.loaded = true;
                    return products;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        refresh() {
            this.products = [];
            if (this.loaded) {
                this.loaded = false;
                this.fetch();
            }
        },
        async createProduct(name: string): Promise<Product> {
            const organisationStore = useOrganisationStore();
            const product = await productService.storeProduct(
                organisationStore.current_organisation,
                name,
            );
            this.products.push(product);
            return product;
        },
        async deleteProduct(id: number): Promise<void> {
            const organisationStore = useOrganisationStore();
            await productService.deleteProduct(
                organisationStore.current_organisation,
                id,
            );
            this.products = this.products.filter((p) => p.id !== id);
        },
    },
});
