import { DateTime } from 'luxon';
import type { IPaymentProcessor } from '@/api/model/PaymentProcessor';
import PaymentProcessor from '@/api/model/PaymentProcessor';

export type DisputeStatus =
    | 'lost'
    | 'needs_response'
    | 'under_review'
    | 'warning_closed'
    | 'warning_needs_response'
    | 'warning_under_review'
    | 'won';

export type DisputeReason =
    | 'bank_cannot_process'
    | 'check_returned'
    | 'credit_not_processed'
    | 'customer_initiated'
    | 'debit_not_authorized'
    | 'duplicate'
    | 'fraudulent'
    | 'general'
    | 'incorrect_account_details'
    | 'insufficient_funds'
    | 'product_not_received'
    | 'product_unacceptable'
    | 'subscription_canceled'
    | 'unrecognized';

export type DisputeCardBrand =
    | 'amex'
    | 'diners'
    | 'discover'
    | 'eftpos_au'
    | 'jcb'
    | 'mastercard'
    | 'unionpay'
    | 'visa'
    | 'unknown';

export interface IDispute {
    id?: number;
    ulid: string;
    external_id: string;
    amount: number;
    currency: string;
    reason: DisputeReason;
    status: DisputeStatus;
    has_evidence: boolean;
    evidence_due_by: string;
    payment_processor_id: number;
    created_at: string;
    updated_at: string;
    processor: IPaymentProcessor;
    payment_id: string;
    card_brand: DisputeCardBrand;
    card_last_4: string;
    customer_name: string;
    customer_email: string;
    charge_description: string;
}

export type WithPaymentProcessor = {
    processor: PaymentProcessor;
};

export default class Dispute {
    id = 0;
    ulid: string = '';
    external_id: string = '';
    amount: number = 0;
    currency: string = 'USD';
    reason: DisputeReason = 'general';
    status: DisputeStatus = 'needs_response';
    has_evidence: boolean = false;
    evidence_due_by: DateTime = DateTime.local();
    payment_processor_id: number = 0;
    created_at: DateTime = DateTime.local();
    updated_at: DateTime = DateTime.local();
    payment_id: string = '';
    card_brand: DisputeCardBrand = 'unknown';
    card_last_4: string = '';
    customer_name: string = '';
    customer_email: string = '';
    charge_description: string = '';

    toJSON(): IDispute {
        //console.debug("Dispute.toJSON()", this);
        return Object.assign({} as IDispute, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: IDispute): Dispute {
        //console.debug('Dispute.fromJSON()', json);
        const user = Object.create(Dispute.prototype);
        return Object.assign(user, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
            evidence_due_by: DateTime.fromISO(json.evidence_due_by),
            processor: json.processor
                ? PaymentProcessor.fromJSON(json.processor)
                : null,
        });
    }
}
