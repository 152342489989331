export interface FromJsonFactory<T, U> {
    fromJSON(json: U): T;
}

export class Meta {
    current_page = 1;
    from = 0;
    last_page = 1;
    per_page = 25;
    to = 0;
    total = 0;

    static fromJSON(json: MetaJson): Meta {
        //console.debug("Meta.fromJson()", json);
        const response = Object.create(Meta.prototype);
        return Object.assign(response, json, {});
    }
}

class Links {
    url = '';
    label = 0;
    active = true;

    static fromJSON(json: LinksJson): Links {
        //console.debug("Links.fromJson()");
        const response = Object.create(Links.prototype);
        return Object.assign(response, json, {});
    }
}

export class PaginatedResponse<T> {
    data: T[] = [];
    links: Links | null = null;
    meta: Meta = new Meta();

    static fromJSON<T, U>(
        json: PaginatedResponseJson<U>,
        jsonFactory: (json: U) => T,
    ): PaginatedResponse<T> {
        //console.debug("PaginatedResponse.fromJson()");
        const response = Object.create(PaginatedResponse.prototype);
        return Object.assign(response, json, {
            data: json.data
                ? json.data.map((json: U) => jsonFactory(json))
                : [],
            links: json.links ? Links.fromJSON(json.links) : [],
            meta: json.meta ? Meta.fromJSON(json.meta) : null,
        });
    }
}

interface MetaJson {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
}

interface LinksJson {
    url: string;
    label: number;
    active: boolean;
}

export interface PaginatedResponseJson<T> {
    data: T[];
    links: LinksJson;
    meta: MetaJson;
}
