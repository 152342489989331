/*
 * Example Users
 */

export default [
    {
        href: 'javascript:void(0)',
        name: 'Judy Ford',
        profession: 'Copywriter',
        avatar: 'avatar2',
        statusColor: 'success',
    },
    {
        href: 'javascript:void(0)',
        name: 'Carl Wells',
        profession: 'Web Developer',
        avatar: 'avatar11',
        statusColor: 'success',
    },
    {
        href: 'javascript:void(0)',
        name: 'Amber Shaw',
        profession: 'Web Designer',
        avatar: 'avatar5',
        statusColor: 'success',
    },
    {
        href: 'javascript:void(0)',
        name: 'Lisa Jekins',
        profession: 'Photographer',
        avatar: 'avatar7',
        statusColor: 'warning',
    },
    {
        href: 'javascript:void(0)',
        name: 'Adam Ford',
        profession: 'Graphic Designer',
        avatar: 'avatar16',
        statusColor: 'warning',
    },
];
