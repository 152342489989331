import axios from 'axios';
import type { IDispute, WithPaymentProcessor } from './model/Dispute';
import Dispute from './model/Dispute';
import type { Organisation } from '@/api/model/Organisation';
import type PageOptions from '@/api/model/PageOptions';
import type { PaginatedResponseJson } from '@/api/model/PaginatedResponse';
import { PaginatedResponse } from '@/api/model/PaginatedResponse';

export default function useDisputesService() {
    return {
        async getAll(
            organisation: Organisation,
            pageOptions: PageOptions,
            includeClosed: boolean,
        ): Promise<PaginatedResponse<Dispute & WithPaymentProcessor>> {
            const query = pageOptions.toURLSearchParams();
            query.append('closed', includeClosed ? 'true' : 'false');

            const response = await axios.get<PaginatedResponseJson<IDispute>>(
                `/api/organisations/${organisation.ulid}/disputes?${query.toString()}`,
            );
            return PaginatedResponse.fromJSON(
                response.data,
                (json: IDispute) =>
                    Dispute.fromJSON(json) as Dispute & WithPaymentProcessor,
            );
        },

        async getOpenCount(organisation: Organisation): Promise<number> {
            const response = await axios.get<{ count: number }>(
                `/api/organisations/${organisation.ulid}/disputes/open/count`,
            );
            return response.data.count;
        },

        async get(id: number): Promise<Dispute> {
            const response = await axios.get<IDispute>(`/api/disputes/` + id);
            return Dispute.fromJSON(response.data);
        },
    };
}
