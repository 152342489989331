import { DateTime } from 'luxon';

export interface OrganisationJson {
    id: number;
    ulid: string;
    name: string;
    logo: string;
    created_at: string;
    updated_at: string;
}

export class Organisation {
    id = 0;
    ulid = 'unknown';
    name = 'Unknown';
    logo = 'Unknown';
    created_at: DateTime = DateTime.now();
    updated_at: DateTime = DateTime.now();

    static fromJSON(json: OrganisationJson): Organisation {
        //console.debug("Organisation.fromJSON()", json);
        const user = Object.create(Organisation.prototype);
        return Object.assign(user, json, {
            created_at: DateTime.fromISO(json.created_at),
            updated_at: DateTime.fromISO(json.updated_at),
        });
    }
}
