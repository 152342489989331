<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useTemplateStore } from '@/stores/template';
import { useAuthStore } from '@/stores/authStore';
import gravatar from 'gravatar';

import { useOrganisationStore } from '@/stores/organisationStore';
import { useErrors } from '@/composables/useErrors';

// Main store and Router
const store = useTemplateStore();
const authStore = useAuthStore();
const organisationStore = useOrganisationStore();
const router = useRouter();

const { showErrors } = useErrors();

// Reactive variables
const baseSearchTerm = ref('');

// On form search submit functionality
function onSubmitSearch() {
    router.push('/backend/pages/generic/search?' + baseSearchTerm.value);
}

const userMenuShow = ref(false);

// When ESCAPE key is hit close the header search section
function eventHeaderSearch(event) {
    if (event.which === 27) {
        event.preventDefault();
        store.headerSearch({ mode: 'off' });
    }
}

// Attach ESCAPE key event listener
onMounted(() => {
    document.addEventListener('keydown', eventHeaderSearch);
});

// Remove keydown event listener
onUnmounted(() => {
    document.removeEventListener('keydown', eventHeaderSearch);
});

function logout() {
    authStore
        .logout()
        .then(() => {
            router.push({ name: 'login' });
        })
        .catch((error) => {
            showErrors(error, 'Failed to logout.');
        });
}
</script>

<template>
    <!-- Header -->
    <header id="page-header">
        <slot>
            <!-- Header Content -->
            <div class="content-header">
                <slot name="content">
                    <!-- Left Section -->
                    <div class="d-flex align-items-center">
                        <slot name="content-left">
                            <!-- Toggle Sidebar -->
                            <button
                                type="button"
                                class="btn btn-sm btn-alt-secondary me-2 d-lg-none"
                                @click="store.sidebar({ mode: 'toggle' })"
                            >
                                <i class="fa fa-fw fa-bars"></i>
                            </button>
                            <!-- END Toggle Sidebar -->

                            <!-- Toggle Mini Sidebar -->
                            <button
                                type="button"
                                class="btn btn-sm btn-alt-secondary me-2 d-none d-lg-inline-block"
                                @click="store.sidebarMini({ mode: 'toggle' })"
                            >
                                <icon-bi-list />
                            </button>
                            <!-- END Toggle Mini Sidebar -->
                        </slot>
                    </div>
                    <!-- END Left Section -->

                    <!-- Right Section -->
                    <div class="d-flex align-items-center">
                        <slot name="content-right">
                            <!-- User Dropdown -->
                            <b-dropdown
                                no-caret
                                size="sm"
                                end
                                offset="2"
                                :toggle-class="userMenuShow ? 'show' : ''"
                                @toggle="userMenuShow = !userMenuShow"
                            >
                                <template #button-content>
                                    <img
                                        v-if="
                                            organisationStore
                                                .current_organisation.logo
                                        "
                                        class="rounded-circle"
                                        :src="
                                            organisationStore
                                                .current_organisation.logo
                                        "
                                        alt="Header Avatar"
                                        style="width: 21px; height: 21px"
                                    />
                                    <icon-bi-people v-else />
                                    <span class="d-none d-sm-inline-block">{{
                                        organisationStore.current_organisation
                                            ?.name
                                    }}</span>
                                    <i
                                        class="fa fa-fw fa-angle-down d-none d-sm-inline-block opacity-50 ms-1 mt-1"
                                    ></i>
                                </template>
                                <b-dropdown-header>
                                    <div
                                        class="pb-3 text-center bg-body-light border-bottom rounded-top"
                                    >
                                        <img
                                            class="img-avatar img-avatar48 img-avatar-thumb"
                                            :src="
                                                authStore.auth.userProfile
                                                    ?.profile_picture ??
                                                gravatar.url(
                                                    authStore.auth.userProfile
                                                        ?.email,
                                                )
                                            "
                                            alt="Header Avatar"
                                        />
                                        <p
                                            class="mt-2 mb-0 fw-medium"
                                            style="color: #bec8d5"
                                        >
                                            {{
                                                authStore.auth.userProfile?.name
                                            }}
                                        </p>
                                        <!-- <p class="mb-0 text-muted fs-sm fw-medium">Web Developer</p> -->
                                    </div>
                                </b-dropdown-header>

                                <template
                                    v-if="
                                        organisationStore.organisations.length >
                                        1
                                    "
                                >
                                    <b-dropdown-item
                                        v-for="organisation in organisationStore.organisations"
                                        :key="organisation.ulid"
                                        @click="
                                            organisationStore.setCurrentOrganisation(
                                                organisation,
                                            )
                                        "
                                    >
                                        <span class="fs-sm fw-medium px-2">
                                            <img
                                                v-if="organisation.logo"
                                                class="rounded-circle"
                                                :src="organisation.logo"
                                                alt="Header Avatar"
                                                style="
                                                    width: 21px;
                                                    height: 21px;
                                                "
                                            />
                                            <icon-bi-people v-else />
                                            {{ organisation.name }}
                                        </span>
                                        <span
                                            v-if="
                                                organisation.ulid ===
                                                organisationStore
                                                    .current_organisation.ulid
                                            "
                                            class="ms-2"
                                            ><icon-bi-check
                                        /></span>
                                    </b-dropdown-item>
                                </template>

                                <b-dropdown-item :to="{ name: 'user.profile' }">
                                    <span class="fs-sm fw-medium px-2"
                                        ><icon-bi-person /> Profile</span
                                    >
                                </b-dropdown-item>

                                <b-dropdown-item
                                    :to="{
                                        name: 'organisation.settings',
                                        params: {
                                            organisation:
                                                organisationStore
                                                    .current_organisation.ulid,
                                        },
                                    }"
                                >
                                    <span class="fs-sm fw-medium px-2"
                                        ><icon-bi-gear /> Organisation
                                        Settings</span
                                    >
                                </b-dropdown-item>

                                <b-dropdown-divider />

                                <b-dropdown-item @click="logout()">
                                    <span class="fs-sm fw-medium px-2"
                                        ><icon-bi-box-arrow-right /> Log
                                        Out</span
                                    >
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- END User Dropdown -->
                        </slot>
                    </div>
                    <!-- END Right Section -->
                </slot>
            </div>
            <!-- END Header Content -->

            <!-- Header Search -->
            <div
                id="page-header-search"
                class="overlay-header bg-body-extra-light"
                :class="{ show: store.settings.headerSearch }"
            >
                <div class="content-header">
                    <form class="w-100" @submit.prevent="onSubmitSearch">
                        <div class="input-group">
                            <button
                                type="button"
                                class="btn btn-alt-danger"
                                @click="store.headerSearch({ mode: 'off' })"
                            >
                                <i class="fa fa-fw fa-times-circle"></i>
                            </button>
                            <input
                                id="page-header-search-input"
                                v-model="baseSearchTerm"
                                type="text"
                                class="form-control"
                                placeholder="Search or hit ESC.."
                                name="page-header-search-input"
                            />
                        </div>
                    </form>
                </div>
            </div>
            <!-- END Header Search -->

            <!-- Header Loader -->
            <div
                id="page-header-loader"
                class="overlay-header bg-body-extra-light"
                :class="{ show: store.settings.headerLoader }"
            >
                <div class="content-header">
                    <div class="w-100 text-center">
                        <i class="fa fa-fw fa-circle-notch fa-spin"></i>
                    </div>
                </div>
            </div>
            <!-- END Header Loader -->
        </slot>
    </header>
    <!-- END Header -->
</template>
