<script setup>
import { useTemplateStore } from '@/stores/template';

import BaseLayout from '@/layouts/BaseLayout.vue';

// Main store
const store = useTemplateStore();

// Set default elements for this layout
store.setLayout({
    header: true,
    sidebar: true,
    sideOverlay: true,
    footer: true,
});

// Set various template options for this layout variation
store.headerStyle({ mode: 'light' });
store.mainContent({ mode: 'narrow' });
</script>

<template>
    <BaseLayout />
</template>
