<template>
    <BToastOrchestrator />
    <RouterView />
</template>

<script setup lang="ts">
import { useDisputeStore } from '@/stores/disputeStore';
import { useWarehouseTransferStore } from '@/stores/warehouseTransferStore';
import { onMounted } from 'vue';

const disputeStore = useDisputeStore();
const warehouseTransferSource = useWarehouseTransferStore();

onMounted(() => {
    disputeStore.fetchCount();
    warehouseTransferSource.fetchCount();
});
</script>

<style lang="scss">
// Main Stylesheet
@import '@/assets/scss/main';

// All color themes are included and available by default
// Feel free to comment out any of them if you won't use them in your project
@import '@/assets/scss/oneui/themes/amethyst';
@import '@/assets/scss/oneui/themes/city';
@import '@/assets/scss/oneui/themes/flat';
@import '@/assets/scss/oneui/themes/modern';
@import '@/assets/scss/oneui/themes/smooth';
</style>
