export interface ICountry {
    id?: number;
    created_at: Date;
    updated_at: Date;
    name: string;
    region_code: string;
    country_code: string;
}

export default class Country {
    id = 0;
    created_at: Date = new Date();
    updated_at: Date = new Date();
    name = '';
    region_code = '';
    country_code = '';

    toJSON(): ICountry {
        //console.debug("Country.toJSON()", this);
        return Object.assign({} as ICountry, this, {
            created_at: undefined,
            updated_at: undefined,
        });
    }

    static fromJSON(json: ICountry): Country {
        //console.debug("Country.fromJSON()", json);
        const user = Object.create(Country.prototype);
        return Object.assign(user, json, {
            created_at: new Date(json.created_at),
            updated_at: new Date(json.updated_at),
        });
    }
}
