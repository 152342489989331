/*
 * Example Activity
 */

export default [
    {
        href: 'javascript:void(0)',
        icon: 'fa fa-fw fa-plus',
        color: 'success',
        title: 'New sale ($15)',
        subtitle: 'Admin Template',
        time: '3 min ago',
    },
    {
        href: 'javascript:void(0)',
        icon: 'fa fa-fw fa-pencil-alt',
        color: 'info',
        title: 'You edited the file',
        subtitle: 'Documentation.doc',
        time: '15 min ago',
    },
    {
        href: 'javascript:void(0)',
        icon: 'fa fa-fw fa-trash',
        color: 'danger',
        title: 'Project deleted',
        subtitle: 'Line Icon Set',
        time: '4 hours ago',
    },
];
